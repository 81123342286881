@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap");

* {
  /* font-size: 12px; */
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
}

body {
  font-size: 16px;
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(238, 241, 248);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* modals */
.transition-element {
  /* opacity: 0; */
  transform: translateY(100px);
  transition: all 0.1s ease-in-out;
}

.fade-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.2s ease-in-out;
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.2s ease-in-out;
}

.fade-enter-done {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.2s ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.2s ease-in-out;
}

.collapsing {
  height: auto !important;
}

/* UNDOING BOOTSTRAP :( */

/* dl,
ol,
ul {
  margin-top: 16px;
  margin-bottom: 16px;
} */

/* .dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(
    var(--bs-border-radius) - var(--bs-border-width)
  );
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
} */

/* Scrollbar */
/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #eef1f8;
}

*::-webkit-scrollbar-track:hover {
  background-color: #eef1f8;
}

*::-webkit-scrollbar-track:active {
  background-color: #eef1f8;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #c4cde0;
  border: 3px solid #eef1f8;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #abb3c3;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #abb3c3;
}
